<template>
  <div class="aside" style="height: 100%">
    <el-row class="tac" style="height: 100%">
      <el-col :span="12" style="height: 100%">
        <el-menu
          :default-active="active ? active : '1-1'"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          style="height: 100%"
        >
          <el-submenu
            v-for="item in menu"
            :key="item.index"
            :index="item.index"
          >
            <template slot="title">
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="child in item.child"
                :key="child.index"
                :index="child.index"
                @click="handleChose(child.index)"
                >{{ child.branch }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      index: "1-1",
      active: "1-1",
      menu: [
        {
          title: "赛事管理",
          index: "1",
          child: [
            {
              index: "1-1",
              branch: "赛事管理",
            },
            // {
            //   index: '1-2',
            //   branch: '退款比例设置',
            // },
            // {
            //   index: '1-3',
            //   branch: '特殊退款审核',
            // },
          ],
        },
        // {
        //   title: '单位&选手',
        //   index: '2',
        //   child: [
        //     {
        //       index: '2-1',
        //       branch: '单位管理',
        //     },
        //     {
        //       index: '2-2',
        //       branch: '个人选手管理',
        //     },
        //   ],
        // },
        {
          title: "统计&对账",
          index: "3",
          child: [
            {
              index: "3-1",
              branch: "报名退款",
            },
            {
              index: "3-2",
              branch: "门票退款",
            },
            {
              index: "3-3",
              branch: "酒店退款",
            },
          ],
        },
        /*{
          title: "奖品管理",
          index: "4",
          child: [
            {
              index: "4-1",
              branch: "单位奖品记录",
            },{
              index: "4-2",
              branch: "奖品补寄管理",
            },
          ],
        }*//*,
        {
          title: "用户管理",
          index: "5",
          child: [
            {
              index: "5-1",
              branch: "用户管理",
            },
          ],
        },*/
        {
          title: "积分",
          index: "6",
          child: [
            {
              index: "6-1",
              branch: "积分管理",
            },
            {
              index: "6-2",
              branch: "榜单管理",
            }
          ],
        },
      ],
    };
  },
  mounted() {
    this.active = this.$route.params.menu;
  },
  inject: ["reload"],
  methods: {
    handleChose(value) {
      console.log(value);
      this.value = value;
      if (value == "1-1") {
        // this.$router.push('/sportsmanagement') //赛事管理
        this.$router.push({
          name: "SportsManagement",
          params: { pageSize: 10, currentPage: 1 },
        }); //赛事管理
        this.reload();
      } else if (value == "1-2") {
        // this.$router.push('/refundratio') // 退款比例
        this.$router.push({ name: "RefundRatio", params: { menu: value } }); // 退款比例
      } else if (value == "1-3") {
        // this.$router.push('/specialrefund') // 特殊退款
        this.$router.push({ name: "SpecialRefund", params: { menu: value } }); // 特殊退款
      } else if (value == "2-1") {
        // this.$router.push('/unitmanagement') //单位管理
        this.$router.push({ name: "UnitManagement", params: { menu: value } }); //单位管理
      } else if (value == "2-2") {
        // this.$router.push('/PlayerManagement') //个人选手管理
        this.$router.push({
          name: "PlayerManagement",
          params: { menu: value },
        }); //个人选手管理
      } else if (value == "3-1") {
        // this.$router.push('/RefundInformation') //退款信息
        this.$router.push({
          name: "RefundInformation",
          params: { menu: value, pageSize: 10, currentPage: 1 },
        }); //报名退款
        this.reload();
      } else if (value == "3-2") {
        this.$router.push({
          name: "TicketRefund",
          params: { menu: value, pageSize: 10, currentPage: 1 },
        }); //门票退款
        this.reload();
      } else if (value == "3-3") {
        this.$router.push({
          name: "HotelRefundTwo",
          params: { menu: value, pageSize: 10, currentPage: 1 },
        }); //酒店退款
        this.reload();
      } else if (value == "4-1") {
        this.$router.push({
          name: "UnitAwardRecord",
          params: { menu: value, pageSize: 10, currentPage: 1 },
        }); //单位奖品记录
        this.reload();
      } else if (value == "4-2") {
        this.$router.push({
          name: "Trophy",
          params: { menu: value, pageSize: 10, currentPage: 1 },
        }); //奖品补寄记录
        this.reload();
      } else if (value == "5-1") {
        this.$router.push({
          name: "User",
          params: { menu: value, pageSize: 10, currentPage: 1 },
        }); //用户管理
        this.reload();
      } else if (value == "6-1") {
        this.$router.push({
          name: "RaceScore",
          params: {menu: value, pageSize: 10, currentPage: 1 },
        }); //积分管理
        this.reload();
      } else if (value == "6-2") {
        this.$router.push({
          name: "ScoreBoard",
          params: {menu: value, pageSize: 10, currentPage: 1 },
        }); //积分管理
        this.reload();
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less">
.aside {
  .logo {
    margin-top: 12px;
    background: url(../assets/logo.png) left center / 100% no-repeat;
    height: 20px;
    // float: left;
    // height: 100%;
    // width: 15%;
    cursor: pointer;
  }
  .el-col-12 {
    width: 100%;
  }
  .el-submenu .el-menu-item {
    min-width: 0;
  }
  .el-menu-item-group__title {
    padding: 0;
  }
}
</style>
