<template>
  <div id="login">
    <div class="loginToHome">
      <el-form
        ref="form"
        :model="form"
        :rules="ruleForm"
        status-icon
        label-width="80px"
        class="loginForm"
      >
        <h3 class="title">i舞赛事管理平台</h3>
        <el-form-item label="用户名" prop="account">
          <el-input
            type="text"
            v-model="form.account"
            auto-complete="off"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="form.password"
            auto-complete="off"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="homeBut"
            type="primary"
            plain
            @click="submit"
            :loading="logining"
            >登录</el-button
          >
          <el-button class="loginBut" type="primary" plain @click="resetForm()"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logining: false,
      form: {
        account: "",
        password: "",
      },
      ruleForm: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    }
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown)
  },

  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false)
  },
  methods: {
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.submit()
      }
    },
    submit(event) {
      console.log(event)
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.logining = true
          try {
            const result = await this.$api.login(
              `account=${this.form.account}&password=${this.form.password}`
            )
            console.log(result)
            if (result.token) {
              this.logining = false
              sessionStorage.setItem("account", this.form.account)
              sessionStorage.setItem("token", result.token)
              this.$store.commit("setToken", result.token)
              this.$router.push({ name: "SportsManagement",params:{pageSize:10,currentPage:1} })
              this.$message({
                type: "success",
                message: "登录成功",
              })
            } else {
              this.logining = false
              this.$alert("用户名或密码错误!", "警告", {
                confirmButtonText: "ok",
              })
            }
          } catch (error) {
            console.log(error)
            return false
          }
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
  },
}
</script>
<style lang="less" scoped>
#login {
  // background: url(../assets/bg3.jpg) left center / 100% no-repeat;
  background: url(../assets/bg2.jpg) left center / 100% no-repeat;
  width: 100%;
  height: 100%;
  .loginToHome {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 400px;
    height: 240px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #dcdfe6;
    .loginForm {
      text-align: center;
      padding-top: 15px;
      padding-right: 30px;
      top: 10px;
      .title {
        display: inline-block;
        margin-left: 50px;
        margin-bottom: 20px;
      }
      .homeBut {
        position: absolute;
        left: 0px;
      }
      .loginBut {
        position: absolute;
        right: 0px;
      }
    }
  }
}
</style>
