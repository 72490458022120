import ajax from './request'
import qs from 'qs'

const URLs = {
  addGroup: process.env.VUE_APP_HOST_TEST + '/competition/group/save',//添加组别
  groupData: process.env.VUE_APP_HOST_TEST + '/competition/group/findGroupList',//获取组别列表信息
  userQuery: process.env.VUE_APP_HOST_TEST + '/competition/user/query',//获取用户管理列表
  userAdd: process.env.VUE_APP_HOST_TEST + '/competition/user/add',//新增用户
  userUpdate: process.env.VUE_APP_HOST_TEST + '/competition/user/update',//编辑用户
  changePassword: process.env.VUE_APP_HOST_TEST + '/competition/user/changePassword',//修改用户密码
  removeUser: process.env.VUE_APP_HOST_TEST + '/competition/user/remove',//删除用户
  findRefundExcelList: process.env.VUE_APP_HOST_TEST + '/competition/manage/findRefundExcelList',//获取退款列表
  deleteGroup: process.env.VUE_APP_HOST_TEST + '/competition/group/delete',//删除组别列表信息
  deleteAllGroup: process.env.VUE_APP_HOST_TEST + '/competition/group/clear',//删除所有组别列表信息
  editGroup: process.env.VUE_APP_HOST_TEST + '/competition/group/update',//修改组别列表信息
  importGroup: process.env.VUE_APP_HOST_TEST + '/competition/group/importGroup',//导入组别
  exportGroup: process.env.VUE_APP_HOST_TEST + '/competition/group/exportGroup',//导出组别
  refundAliPayOrder: process.env.VUE_APP_HOST_TEST + '/competition/manage/refundAliPayOrder',//导入支付宝
  uploadApplyExcel: process.env.VUE_APP_HOST_TEST + '/competition/apply/uploadApplyExcel',//导入报名表
  uploadWeChatRefundExcel: process.env.VUE_APP_HOST_TEST + '/competition/manage/uploadWeChatRefundExcel',//导入微信
}

const addGroup = (options) => ajax.post(URLs.addGroup, qs.stringify(options))
const groupData = (data) => ajax.post(URLs.groupData, data)
const userQuery = (data) => ajax.post(URLs.userQuery, data)
const userAdd = (options) => ajax.post(URLs.userAdd, qs.stringify(options))
const userUpdate = (data) => ajax.post(URLs.userUpdate, data)
const removeUser = (id) => ajax.post(URLs.removeUser, id)
const changePassword = (data) => ajax.post(URLs.changePassword, data)
const findRefundExcelList = (data) => ajax.post(URLs.findRefundExcelList, data)
const deleteGroup = (id) => ajax.post(URLs.deleteGroup, id)
const deleteAllGroup = (id) => ajax.post(URLs.deleteAllGroup, id)
const editGroup = (options) => ajax.post(URLs.editGroup, qs.stringify(options))
const importGroup = (options) => ajax.post(URLs.importGroup, options)
const refundAliPayOrder = (options) => ajax.post(URLs.refundAliPayOrder, options)
const uploadApplyExcel = (options) => ajax.post(URLs.uploadApplyExcel, options)
const uploadWeChatRefundExcel = (options) => ajax.post(URLs.uploadWeChatRefundExcel, options)
const exportGroup = (options) => ajax.post(URLs.exportGroup, options, { responseType: 'arraybuffer' })

export default {
  URLs,
  addGroup,
  groupData,
  findRefundExcelList,
  deleteGroup,
  deleteAllGroup,
  editGroup,
  importGroup,
  exportGroup,
  uploadWeChatRefundExcel,
  refundAliPayOrder,
  uploadApplyExcel,
  userQuery,
  userAdd,
  userUpdate,
  changePassword,
  removeUser,
}