import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api';
import axios from 'axios'
import moment from 'moment'//导入文件

Vue.use(ElementUI);
Vue.prototype.$api = api

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;//赋值使用
moment.locale('zh-cn');//需要汉化
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
console.log(process.env.VUE_APP_HOST_TEST)
