import ajax from './request'
import qs from 'qs'

const URLs = {
  findApply:process.env.VUE_APP_HOST_TEST+'/competition/apply/findApplyList',//查询赛事报名信息
  findApplyUnit:process.env.VUE_APP_HOST_TEST+'/competition/apply/findApplyUnitsList',//查询单位报名信息
  findApplyUnitPlayer:process.env.VUE_APP_HOST_TEST+'/competition/apply/queryCompetitionUnitPlayer',//查询单位报名选手信息
  toBePay:process.env.VUE_APP_HOST_TEST+'/competition/apply/findUnPayList',//待支付
  deleteToBePay:process.env.VUE_APP_HOST_TEST+'/competition/apply/deleteUnPay',//删除待支付信息
  findPersonal:process.env.VUE_APP_HOST_TEST+'/competition/apply/findPersonalApplyList',//查询个人报名信息
  exportApplyInfo:process.env.VUE_APP_HOST_TEST+'/competition/apply/downloadApplyInfo',//导出赛事报名信息
  exportApplyPayInfo:process.env.VUE_APP_HOST_TEST+'/competition/apply/downloadApplyPayInfo',//导出赛事报名支付信息
  exportAllPlayers:process.env.VUE_APP_HOST_TEST+'/competition/manage/exportAllPlayers',//导出赛事报名支付信息
  exportApplyUnit:process.env.VUE_APP_HOST_TEST+'/competition/apply/downloadCompetitionUnits',//导出赛事报名单位信息
  exportUnPay:process.env.VUE_APP_HOST_TEST+'/competition/apply/downloadUnPay',//导出待支付信息表

}

const findApply = (options) => ajax.post(URLs.findApply, options)
const findApplyUnit = (options) => ajax.post(URLs.findApplyUnit, options)
const findApplyUnitPlayer = (options) => ajax.post(URLs.findApplyUnitPlayer,options)
const toBePay = (options) => ajax.post(URLs.toBePay, options)
const deleteToBePay = (options) => ajax.post(URLs.deleteToBePay, options)
const findPersonal = (options) => ajax.post(URLs.findPersonal, options)
const exportApplyInfo = (options) => ajax.post(URLs.exportApplyInfo,options,{ responseType: 'arraybuffer' })
const exportApplyPayInfo = (options) => ajax.post(URLs.exportApplyPayInfo,options,{ responseType: 'arraybuffer' })
const exportAllPlayers = (options) => ajax.post(URLs.exportAllPlayers,options,{ responseType: 'arraybuffer' })
const exportApplyUnit = (options) => ajax.post(URLs.exportApplyUnit,options,{ responseType: 'arraybuffer' })
const exportUnPay = (options) => ajax.post(URLs.exportUnPay,options,{ responseType: 'arraybuffer' })




export default {
  findApply,
  findApplyUnit,
  findApplyUnitPlayer,
  toBePay,
  findPersonal,
  exportApplyInfo,
  exportApplyPayInfo,
  exportAllPlayers,
  exportApplyUnit,
  exportUnPay,
  deleteToBePay,
}