import ajax from './request'
import qs from 'qs'

const URLs = {
  querySchedule: process.env.VUE_APP_HOST_TEST + '/competition/schedule/querySchedule',//总赛程列表
  clearSchedule: process.env.VUE_APP_HOST_TEST + '/competition/schedule/clearSchedule',//清空总赛程
  importSchedule: process.env.VUE_APP_HOST_TEST + '/competition/schedule/importSchedule',//导入总赛程
  queryScheduleGroup: process.env.VUE_APP_HOST_TEST + '/competition/schedule/queryScheduleGroup',//分组赛程列表
  importScheduleGroup: process.env.VUE_APP_HOST_TEST + '/competition/schedule/importScheduleGroup',//导入分组赛程
  clearScheduleGroup: process.env.VUE_APP_HOST_TEST + '/competition/schedule/clearScheduleGroup',//清空分组赛程
  queryScore: process.env.VUE_APP_HOST_TEST + '/competition/schedule/queryScore',//成绩单列表
  importScore: process.env.VUE_APP_HOST_TEST + '/competition/schedule/importScore',//导入成绩单
  clearScore: process.env.VUE_APP_HOST_TEST + '/competition/schedule/clearScore',//清空成绩单
  queryCertificate: process.env.VUE_APP_HOST_TEST + '/competition/schedule/queryCertificate',//证书列表
  importCertificate: process.env.VUE_APP_HOST_TEST + '/competition/schedule/importCertificate',//导入证书
  clearCertificate: process.env.VUE_APP_HOST_TEST + '/competition/schedule/clearCertificate',//清空证书
}

//总赛程列表
const querySchedule = (data) => ajax.post(URLs.querySchedule, data)
//清空总赛程
const clearSchedule = (data) => ajax.post(URLs.clearSchedule, data)
//导入总赛程
const importSchedule = (data) => ajax.post(URLs.importSchedule, data)
//分组赛程列表
const queryScheduleGroup = (data) => ajax.post(URLs.queryScheduleGroup, data)
//清空分组赛程
const clearScheduleGroup = (data) => ajax.post(URLs.clearScheduleGroup, data)
//导入分组赛程
const importScheduleGroup = (data) => ajax.post(URLs.importSchedule, data)
//成绩单列表
const queryScore = (data) => ajax.post(URLs.queryScore, data)
//清空成绩单
const clearScore = (data) => ajax.post(URLs.clearScore, data)
//导入成绩单
const importScore = (data) => ajax.post(URLs.importScore, data)
//证书列表
const queryCertificate = (data) => ajax.post(URLs.queryCertificate, data)
//清空证书
const clearCertificate = (data) => ajax.post(URLs.clearCertificate, data)
//导入证书
const importCertificate = (data) => ajax.post(URLs.importCertificate, data)

export default {
  URLs,
  querySchedule,
  clearSchedule,
  importSchedule,
  queryScheduleGroup,
  clearScheduleGroup,
  importScheduleGroup,
  queryScore,
  clearScore,
  importScore,
  queryCertificate,
  clearCertificate,
  importCertificate,
}