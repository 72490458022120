import ajax from './request'
// import qs from 'qs'

const URLs = {
  login: process.env.VUE_APP_HOST_TEST + '/competition/user/doLogin', //登录
  logout: process.env.VUE_APP_HOST_TEST + '/competition/user/logout', //登出
  getUserInfo: process.env.VUE_APP_HOST_TEST + '/competition/user/getUserInfo', //获取用户信息
}

const login = (data) => ajax.post(URLs.login, data)
const logout = () => ajax.post(URLs.logout)
const getUserInfo = () => ajax.post(URLs.getUserInfo)

export default {
  login,
  logout,
  getUserInfo,
}