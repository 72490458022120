<template>
  <div class="header">
    <div class="logo"></div>
    <ul class="nav">
      <li class="admin">{{ admin }}</li>
      <li class="logout" @click="logout">
        <i class="iconfont iconfont-button">&#xe658;</i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: [
        [
          "赛事管理",
          {
            value: "选项1",
            label: "赛事管理",
          },
          {
            value: "选项2",
            label: "退款比例设置",
          },
          {
            value: "选项3",
            label: "特殊退款审核",
          },
        ],
        [
          "单位&选手",
          {
            value: "选项4",
            label: "单位管理",
          },
          {
            value: "选项5",
            label: "个人选手管理",
          },
        ],
        [
          "统计&对账",
          {
            value: "选项6",
            label: "退款信息",
          },
        ],
      ],
      username: "supername",
    }
  },
  props: ["admin"],
  methods: {
    //旋转不同标签后的change事件
    selectChanged(value) {
      if (value == "选项1") {
        this.$router.push("/sportsmanagement") //赛事管理
      } else if (value == "选项2") {
        this.$router.push("/refundratio") // 退款比例
      } else if (value == "选项3") {
        this.$router.push("/specialrefund") // 特殊退款
      } else if (value == "选项4") {
        this.$router.push("/unitmanagement") //单位管理
      } else if (value == "选项5") {
        this.$router.push("/PlayerManagement") //个人选手管理
      } else if (value == "选项6") {
        this.$router.push("/RefundInformation") //退款信息
      }
    },
    logout() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.logout()
          console.log(result)
          if (result.data.status_code == "200") {
            this.$router.push("/login")
          }
        } catch (error) {
          console.log(error)
        }
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "iconfont"; /* Project id 2817395 */
  src: url("//at.alicdn.com/t/font_2817395_zp8rfs3415g.woff2?t=1631955624349")
      format("woff2"),
    url("//at.alicdn.com/t/font_2817395_zp8rfs3415g.woff?t=1631955624349")
      format("woff"),
    url("//at.alicdn.com/t/font_2817395_zp8rfs3415g.ttf?t=1631955624349")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.iconfont-button {
  font-size: 24px;
  color: rgba(255, 0, 0, 0.5);
  &:hover {
    color: rgba(255, 0, 0, 1);
  }
}
ul,
li {
  list-style: none;
}
.header {
  margin-top: 10px;
  // background: #000;
  height: 40px;
  width: 100%;
  // display: flex;
  .nav {
    display: flex;
    float: right;
    .select {
      margin-right: 5px;
    }
    .admin {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
    .logout {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      // background: yellow;
      margin-left: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .logo {
    // margin-top: 12px;
    background: url(../assets/logo.png) left center / 100% no-repeat;
    // height: 20px;
    float: left;
    height: 60px;
    width: 280px;
    // cursor: pointer;
  }
}
.el-select-dropdown__list li:first-child {
  display: none;
}
</style>
