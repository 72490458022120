import ajax from './request'
import qs from 'qs'

const URLs = {
  addGame: process.env.VUE_APP_HOST_TEST + '/competition/manage/save',//添加赛事
  getData: process.env.VUE_APP_HOST_TEST + '/competition/manage/query',//获取赛事信息、查询
  deleteData: process.env.VUE_APP_HOST_TEST + '/competition/manage/delete',//删除赛事信息
  cancelCompetition: process.env.VUE_APP_HOST_TEST + '/competition/manage/cancelCompetition',//取消赛事
  clearUnPayApplyList: process.env.VUE_APP_HOST_TEST + '/competition/manage/clearUnPayApplyList',//清理未支付报名
  isShow: process.env.VUE_APP_HOST_TEST + '/competition/manage/updateIsShow',//修改赛事是否可见
  edit: process.env.VUE_APP_HOST_TEST + '/competition/manage/update',//修改赛事信息
  baseInfo: process.env.VUE_APP_HOST_TEST + '/competition/manage/getCompetitionInfo',//获取赛事基本信息
  hotelDrop: process.env.VUE_APP_HOST_TEST + '/competition/manage/findHotel',//酒店信息下拉框
  unitDrop: process.env.VUE_APP_HOST_TEST + '/competition/manage/findUnit',//单位信息下拉框
  exportToScore: process.env.VUE_APP_HOST_TEST + '/competition/manage/download4JF',//导出到计分
  exportOrderInfo: process.env.VUE_APP_HOST_TEST + '/competition/manage/exportOrderInfo',//导出订单明细
  uploadCompetitionImg: process.env.VUE_APP_HOST_TEST + '/competition/manage/getIconUploadConfig',//上传赛事封面和入群二维码
}

const addGame = (options) => ajax.post(URLs.addGame,
  qs.stringify(options) //转化form Data的格式进行数据传递
)
const getData = (data) => ajax.post(URLs.getData, data)
const deleteData = (id) => ajax.post(URLs.deleteData, id)
const cancelCompetition = (id) => ajax.post(URLs.cancelCompetition, id)
const clearUnPayApplyList = (id) => ajax.post(URLs.clearUnPayApplyList, id)
const isShow = (data) => ajax.post(URLs.isShow, data)
const edit = (options) => ajax.post(URLs.edit, qs.stringify(options))
const baseInfo = (id) => ajax.post(URLs.baseInfo, id)
//酒店信息下拉框
const hotelDrop = () => ajax.post(URLs.hotelDrop)
//单位信息下拉框
const unitDrop = () => ajax.post(URLs.unitDrop)
//导出到计分
const exportToScore = (data) => ajax.post(URLs.exportToScore, data, { responseType: 'arraybuffer' })
//导出订单明细
const exportOrderInfo = (data) => ajax.post(URLs.exportOrderInfo, data, { responseType: 'arraybuffer' })
//上传赛事封面和入群二维码
const uploadCompetitionImg = (data) => ajax.post(URLs.uploadCompetitionImg, data)




export default {
  addGame,
  getData,
  deleteData,
  cancelCompetition,
  clearUnPayApplyList,
  isShow,
  edit,
  baseInfo,
  hotelDrop,
  unitDrop,
  exportToScore,
  exportOrderInfo,
  uploadCompetitionImg,
}