import ajax from './request'
import qs from 'qs'

const URLs = {
  insertRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/insertRefund',//新增退款比例
  findRefundProportionList: process.env.VUE_APP_HOST_TEST + '/competition/refund/findRefundProportionList',//获取退款比例
  updateRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/updateRefund',//编辑退款比例
  deleteRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/deleteRefund',//删除退款比例
  findRefundInfo: process.env.VUE_APP_HOST_TEST + '/competition/refund/findRefundInfo',//报名退款信息列表
  query: process.env.VUE_APP_HOST_TEST + '/competition/manage/prize/query',//奖品信息列表
  check: process.env.VUE_APP_HOST_TEST + '/competition/manage/prize/check',//审核奖品
  findTicketRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/findRefundTicketInfo',//报名退款信息列表
  findRefundHotelInfo: process.env.VUE_APP_HOST_TEST + '/competition/refund/findRefundHotelInfo',//酒店退款信息列表
  findSpecialRefundList: process.env.VUE_APP_HOST_TEST + '/competition/refund/findSpecialRefundList',//特殊退款审核
  checkRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/checkRefund',//审核退款
  exportApplyRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/downloadApplyRefund',//导出报名退款信息
  exportTicketRefund: process.env.VUE_APP_HOST_TEST + '/competition/refund/downloadTicketRefund',//导出门票退款信息


}

//退款比例新增
const insertRefund = (options) => ajax.post(URLs.insertRefund,
  qs.stringify(options) //转化form Data的格式进行数据传递
)
//退款比例列表
const findRefundProportionList = (data) => ajax.post(URLs.findRefundProportionList, data)
//退款编辑
const editRefund = (options) => ajax.post(URLs.updateRefund,
  qs.stringify(options) //转化form Data的格式进行数据传递
)
//退款比例删除
const deleteRefund = (id) => ajax.post(URLs.deleteRefund, id)

//报名退款信息列表
const findRefundInfo = (data) => ajax.post(URLs.findRefundInfo, data)

//报名退款信息列表
const query = (data) => ajax.post(URLs.query, data)

//特殊退款审核列表
const findSpecialRefundList = (data) => ajax.post(URLs.findSpecialRefundList, data)
//审核奖品
const check = (data) => ajax.post(URLs.check, data)
//审核特殊退款
const checkRefund = (data) => ajax.post(URLs.checkRefund, data)
//门票退款信息列表
const findTicketRefund = (data) => ajax.post(URLs.findTicketRefund, data)
//门票退款信息列表
const findRefundHotelInfo = (data) => ajax.post(URLs.findRefundHotelInfo, data)
//导出报名退款信息
const exportApplyRefund = (data) => ajax.post(URLs.exportApplyRefund, data, { responseType: 'arraybuffer' })
//导出门票退款信息
const exportTicketRefund = (data) => ajax.post(URLs.exportTicketRefund, data, { responseType: 'arraybuffer' })


export default {
  insertRefund,
  editRefund,
  deleteRefund,
  findRefundInfo,
  findSpecialRefundList,
  checkRefund,
  findRefundProportionList,
  findTicketRefund,
  findRefundHotelInfo,
  exportApplyRefund,
  exportTicketRefund,
  query,
  check,
}