import ajax from './request'
import qs from 'qs'

const URLs = {
  unitAwardQuery: process.env.VUE_APP_HOST_TEST + '/competition/award/unitAwardList',//单位奖品记录列表
  awardRecordInfo: process.env.VUE_APP_HOST_TEST + '/competition/award/getUnitAwardRecordInfoList',//单位奖品详细记录列表
  awardLogisticsList: process.env.VUE_APP_HOST_TEST + '/competition/award/getAwardLogisticsList',//获取物流信息
};

//报名退款信息列表
const unitAwardList = (data) => ajax.post(URLs.unitAwardQuery, data);
const awardRecordInfo = (data) => ajax.post(URLs.awardRecordInfo, data);
const awardLogisticsList = (data) => ajax.post(URLs.awardLogisticsList, data);

export default {
  unitAwardList,
  awardRecordInfo,
  awardLogisticsList,
}