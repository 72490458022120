import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {name: 'SportsManagement', params: {pageSize: 10, currentPage: 1}},
    meta: {
        requiresAuth: true
    },
    children: [{
        path: '/sportsmanagement/:pageSize/:currentPage',
        name: 'SportsManagement',
        component: () => import('../views/Sports/SportsManagement.vue'),
        meta: {
            requiresAuth: true
        },
    },
        {
            path: '/refundratio/:back/:id/:name/:pageSize/:currentPage',
            name: 'RefundRatio',
            meta: {
                requiresAuth: true
            },
            component: () => import('../views/Sports/RefundRatio.vue')
        },
        {
            path: '/specialrefund/:menu',
            name: 'SpecialRefund',
            component: () => import('../views/Sports/SpecialRefund.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/unitmanagement/:menu',
            name: 'UnitManagement',
            component: () => import('../views/Sports/UnitManagement.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/playermanagement/:menu',
            name: 'PlayerManagement',
            component: () => import('../views/Sports/PlayerManagement.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/refundinformation/:menu/:pageSize/:currentPage',
            name: 'RefundInformation',
            component: () => import('../views/Sports/RefundInformation.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/ticketrefund/:menu/:pageSize/:currentPage',
            name: 'TicketRefund',
            component: () => import('../views/Sports/TicketRefund.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/hoterlrefundtwo/:menu/:pageSize/:currentPage',
            name: 'HotelRefundTwo',
            component: () => import('../views/Sports/HotelRefundTwo.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/trophy/:menu/:pageSize/:currentPage',
            name: 'Trophy',
            component: () => import('../views/Sports/Trophy.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/unitAwardRecord/:menu/:pageSize/:currentPage',
            name: 'UnitAwardRecord',
            component: () => import('../views/Sports/UnitAwardRecord.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/awardRecordList/:menu/:pageSize/:currentPage',
            name: 'AwardRecordList',
            component: () => import('../views/Sports/AwardRecordList.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/awardLogisticsList/:menu/:pageSize/:currentPage',
            name: 'AwardLogisticsList',
            component: () => import('../views/Sports/AwardLogisticsList.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/user/:menu/:pageSize/:currentPage',
            name: 'User',
            component: () => import('../views/Sports/User.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/refundMoney/:menu/:pageSize/:currentPage',
            name: 'RefundMoney',
            component: () => import('../views/Sports/RefundMoney.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: `/sportsdetail/:back/:id/:name/:menu/:pageSize/:currentPage`,
            name: 'SportsDetail',
            component: () => import('../views/Sports/SportsDetail.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: `/racescore/:menu/:pageSize/:currentPage`,
            name: 'RaceScore',
            component: () => import('../views/Sports/RaceScore.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: `/racescore/:menu/:pageSize/:currentPage`,
            name: 'ScoreBoard',
            component: () => import('../views/Sports/ScoreBoard.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/discount/:back/:id/:name/:endTime',
            name: 'Discount',
            meta: {
                requiresAuth: true
            },
            component: () => import('../views/Sports/Discount.vue')
        },
        {
            path: '/companyTabs/:back',
            name: 'CompanyTabs',
            component: () => import('../views/Sports/CompanyTabs.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/admissionticket/:back/:id/:name/:pageSize/:currentPage',
            name: 'AdmissionTicket',
            component: () => import('../views/Sports/AdmissionTicket.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/unitdetail/:competitionId/:unitId/:back/:name',
            name: 'UnitDetail',
            component: () => import('../views/Sports/UnitDetail.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/hotel/:back/:id/:name/:pageSize/:currentPage/:pageSizeTwo/:currentPageTwo',
            name: 'Hotel',
            component: () => import('../views/Sports/Hotel.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/hotelroomtype/:back/:id/:hotelId/:name/:eDate/:lDate/:pageSize/:currentPage/:pageSizeTwo/:currentPageTwo',
            name: 'HotelRoomType',
            component: () => import('../views/Sports/HotelRoomType.vue'),
            meta: {
                requiresAuth: true
            },
        },

    ]

},
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// //为路由对象，添加beforEach导航守卫
router.beforeEach((to, from, next) => {
    // 可以在路由元信息指定哪些页面需要登录权限
    const islogin = sessionStorage.getItem('token') // 假设没有登录（这里应从接口获取）
    if (to.meta.requiresAuth && !islogin) { // 需要登录授权，这里还需要判断是否登录
        next('/login') // 跳转到登录
        return
    }
    // if (!islogin) { // 需要登录授权，这里还需要判断是否登录
    //   next('/login') // 跳转到登录
    //   return
    // }
    next()
})

export default router
