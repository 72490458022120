import axios from "axios"
import store from '../store'
import {
  Message
} from 'element-ui';
import router from '../router';

const ajax = axios.create(); //创建一个axios的实例
ajax.interceptors.request.use(config => { //请求拦截，处理请求头
    if (sessionStorage.token) {
      // config.headers.Authorization = store.state.token
      config.headers.Authorization = sessionStorage.token
      config.headers["Content-type"] = 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

ajax.interceptors.response.use(function (resp) { //响应拦截，处理相应信息
  // console.log(resp)
  if (resp.data.status_code == 1001) {
    router.replace({
      name: 'Login'
    })
    Message.warning('登录已过期，请重新登录')
    return resp
  }else if (resp.data.status_code == 200 || resp.data.status_code == 1002 ||resp.status == 200) {
    if (resp.data.data) {
      return resp.data.data
    } else {
      return resp
    }

  }
})
export default ajax