import ajax from './request'
import qs from 'qs'

const URLs = {
  boardYearList: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/boardYearList',//榜单年度列表
  boardList: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/boardList',//榜单列表
  raceList: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/raceList',//比赛列表
  uploadHistory: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/uploadHistory',//上传历史记录
  boardScore: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/boardScore',//上传排行
  boardPlayer: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/boardPlayer',//榜单排行
  boardRefresh: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/boardRefresh',//榜单排行
  resetBoardScore: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/resetBoardScore',//榜单排行
  boardPlayerRecord: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/boardPlayerRecord',
  removeBoardPlayerRecord: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/removeBoardPlayerRecord',
  updateBoardPlayerRecord: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/updateBoardPlayerRecord',
  updateBoardPlayer: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/updateBoardPlayer',

  removeScoreBoard: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/removeScoreBoard',
  updateScoreBoard: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/saveScoreBoard',
  saveScoreBoardYear: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/saveScoreBoardYear',
  imageUploadConfig: process.env.VUE_APP_HOST_TEST + '/competition/scoreBoard/imageUploadConfig',
}
const loadBoardYearList = (data) => ajax.post(URLs.boardYearList, data)
const loadBoardList = (data) => ajax.post(URLs.boardList, data)
const loadRaceList = (data) => ajax.post(URLs.raceList, data)
const loadUploadHistory = (data) => ajax.post(URLs.uploadHistory, data)
const importBoardScore = (data) => ajax.post(URLs.boardScore, data)
const loadBoardPlayer = (data) => ajax.post(URLs.boardPlayer, data)
const boardRefresh = (data) => ajax.post(URLs.boardRefresh, data)
const resetBoardScore = (data) => ajax.post(URLs.resetBoardScore, data)
const loadBoardPlayerRecord = (data) => ajax.post(URLs.boardPlayerRecord, data)
const deleteBoardPlayerRecord = (data) => ajax.post(URLs.removeBoardPlayerRecord, data)
const saveBoardPlayerRecord = (data) => ajax.post(URLs.updateBoardPlayerRecord, qs.stringify(data))
const saveBoardPlayer = (data) => ajax.post(URLs.updateBoardPlayer, qs.stringify(data))
const deleteScoreBoard = (data) => ajax.post(URLs.removeScoreBoard, data)
const saveScoreBoard = (data) => ajax.post(URLs.updateScoreBoard, qs.stringify(data))
const saveScoreBoardYear = (data) => ajax.post(URLs.saveScoreBoardYear, qs.stringify(data))
const imageUploadConfig = (data) => ajax.post(URLs.imageUploadConfig, data)

export default {
  URLs,
  loadBoardYearList,
  loadBoardList,
  loadRaceList,
  loadUploadHistory,
  importBoardScore,
  loadBoardPlayer,
  boardRefresh,
  resetBoardScore,
  loadBoardPlayerRecord,
  deleteBoardPlayerRecord,
  saveBoardPlayerRecord,
  saveBoardPlayer,
  deleteScoreBoard,
  saveScoreBoard,
  saveScoreBoardYear,
  imageUploadConfig
}
