import ajax from './request'
import qs from 'qs'

const URLs = {
  addTicket:process.env.VUE_APP_HOST_TEST+'/competition/ticket/saveTicket',//新增门票信息
  addTicketNum:process.env.VUE_APP_HOST_TEST+'/competition/ticket/increaseTicketCount',//增加门票发行量
  editTicket:process.env.VUE_APP_HOST_TEST+'/competition/ticket/updateTicket',//修改门票信息
  deleteTicket:process.env.VUE_APP_HOST_TEST+'/competition/ticket/deleteTicket',//删除门票信息
  getTicketData:process.env.VUE_APP_HOST_TEST+'/competition/ticket/listAllTicket',//获取门票列表信息
  getPayInfo:process.env.VUE_APP_HOST_TEST+'/competition/ticket/findTicketPayList',//获取支付信息列表
  addTicketDis:process.env.VUE_APP_HOST_TEST+'/competition/ticket/saveOrUpdateTicketDiscount',//新增门票折扣信息
  getTicketDisData:process.env.VUE_APP_HOST_TEST+'/competition/ticket/listAllTicketDiscount',//获取门票折扣信息
  deleteTicketDisData:process.env.VUE_APP_HOST_TEST+'/competition/ticket/deleteTicketDiscount',//删除门票折扣信息
  exportTicketPayInfo:process.env.VUE_APP_HOST_TEST+'/competition/ticket/exportTicketPay',//导出门票支付信息

}

const addTicket = (options) => ajax.post(URLs.addTicket, qs.stringify(options))
const addTicketNum = (options) => ajax.post(URLs.addTicketNum, options)
const editTicket = (options) => ajax.post(URLs.editTicket, qs.stringify(options))
const deleteTicket = (options) => ajax.post(URLs.deleteTicket, options)
const getTicketData = (options) => ajax.post(URLs.getTicketData, options)
const getPayInfo = (options) => ajax.post(URLs.getPayInfo, options)
const addTicketDis = (options) => ajax.post(URLs.addTicketDis, qs.stringify(options))
const getTicketDisData = (options) => ajax.post(URLs.getTicketDisData, options)
const deleteTicketDisData = (options) => ajax.post(URLs.deleteTicketDisData, options)
const exportTicketPayInfo = (options) => ajax.post(URLs.exportTicketPayInfo, options,{ responseType: 'arraybuffer' })

export default {
  addTicket,
  addTicketNum,
  editTicket,
  deleteTicket,
  getTicketData,
  getPayInfo,
  addTicketDis,
  getTicketDisData,
  deleteTicketDisData,
  exportTicketPayInfo,
}