import ajax from './request'
import qs from 'qs'

const URLs = {
  getDiscountList: process.env.VUE_APP_HOST_TEST + '/competition/apply/findDiscountExcelRecordList',//获取报名折扣列表
  exportDiscount: process.env.VUE_APP_HOST_TEST + '/competition/apply/exportApplyDiscountExcel',//导出报名折扣表
  exportDiscountDetail: process.env.VUE_APP_HOST_TEST + '/competition/apply/exportApplyDiscountInfoExcel',//导出报名折扣订单详细表 
  exportDiscountTicket: process.env.VUE_APP_HOST_TEST + '/competition/ticket/exportTicketDiscountExcel',//导出门票折扣表
  exportDiscountDetailTicket: process.env.VUE_APP_HOST_TEST + '/competition/ticket/exportTicketDiscountInfoExcel',//导出门票折扣订单详细表
  importDiscountExcel: process.env.VUE_APP_HOST_TEST + '/competition/apply/uploadDiscountRefundExcel',//上传核对后表格
}
const getDiscountList = (options) => ajax.post(URLs.getDiscountList,options)
const exportDiscount = (data) => ajax.post(URLs.exportDiscount, data,{ responseType: 'arraybuffer' })
const exportDiscountDetail = (data) => ajax.post(URLs.exportDiscountDetail, data,{ responseType: 'arraybuffer' })
const exportDiscountTicket = (data) => ajax.post(URLs.exportDiscountTicket, data,{ responseType: 'arraybuffer' })
const exportDiscountDetailTicket = (data) => ajax.post(URLs.exportDiscountDetailTicket, data,{ responseType: 'arraybuffer' })
const importDiscountExcel = (data) => ajax.post(URLs.importDiscountExcel, data)


export default {
  URLs,
  getDiscountList,
  exportDiscount,
  exportDiscountDetail,
  exportDiscountTicket,
  exportDiscountDetailTicket,
  importDiscountExcel
}