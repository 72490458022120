import ajax from './request'
import qs from 'qs'

const URLs = {
  confirmBookingInfo: process.env.VUE_APP_HOST_TEST + '/competition/room/confirmBookingInfo',//确认信息
  saveHotelRefundRecord: process.env.VUE_APP_HOST_TEST + '/competition/room/saveHotelRefundRecord',//获取亏款入住人
  getBookUserList: process.env.VUE_APP_HOST_TEST + '/competition/room/getBookUserList',//获取亏款入住人
  hotelData: process.env.VUE_APP_HOST_TEST + '/competition/room/queryHotel',//获取酒店列表信息
  hotelAdd: process.env.VUE_APP_HOST_TEST + '/competition/room/saveHotel',//新增酒店信息
  uploadImg: process.env.VUE_APP_HOST_TEST + '/competition/room/getIconUploadConfig',//上传酒店图片
  hotelEdit: process.env.VUE_APP_HOST_TEST + '/competition/room/updateHotel',//修改酒店信息
  hotelReserve: process.env.VUE_APP_HOST_TEST + '/competition/room/queryBookingInfo',//预定酒店信息
  addRoomType: process.env.VUE_APP_HOST_TEST + '/competition/room/saveRoom',//新增酒店房型
  getRoomType: process.env.VUE_APP_HOST_TEST + '/competition/room/listAllRoom',//获取酒店房型信息
  hotelDelete: process.env.VUE_APP_HOST_TEST + '/competition/room/deleteHotel',//删除酒店信息
  exportReserve: process.env.VUE_APP_HOST_TEST + '/competition/room/downloadBookingHotel',//导出酒店预定信息
  getRoomInfo: process.env.VUE_APP_HOST_TEST + '/competition/room/listRoomInfo',//获取酒店房间数量列表
  editRoomInfo: process.env.VUE_APP_HOST_TEST + '/competition/room/updateRoom',//编辑酒店房间数量列表
  addRoomNum: process.env.VUE_APP_HOST_TEST + '/competition/room/saveRoomInfo',//增加房间数量
  addInventory: process.env.VUE_APP_HOST_TEST + '/competition/room/updateRemainingNum',//修改不同日期的房间库存量

}
const confirmBookingInfo = (options) => ajax.post(URLs.confirmBookingInfo, options)
//确定信息
const saveHotelRefundRecord = (options) => ajax.post(URLs.saveHotelRefundRecord, options)
//获取亏款入住人
const getBookUserList = (data) => ajax.post(URLs.getBookUserList, data)
//获取酒店列表信息
const hotelData = (data) => ajax.post(URLs.hotelData, data)
//新增酒店信息
const hotelAdd = (options) => ajax.post(URLs.hotelAdd, qs.stringify(options))
//上传酒店图片
const uploadImg = (data) => ajax.post(URLs.uploadImg, data)
//修改酒店信息
const hotelEdit = (options) => ajax.post(URLs.hotelEdit, qs.stringify(options))
//预定酒店信息
const hotelReserve = (options) => ajax.post(URLs.hotelReserve, options)
//新增酒店房型
const addRoomType = (options) => ajax.post(URLs.addRoomType, qs.stringify(options))
//获取酒店房型信息
const getRoomType = (options) => ajax.post(URLs.getRoomType, options)
//删除酒店信息
const hotelDelete = (options) => ajax.post(URLs.hotelDelete, options)
//导出酒店预定信息
const exportReserve = (data) => ajax.post(URLs.exportReserve, data, { responseType: 'arraybuffer' })
//获取酒店房间数量列表
const getRoomInfo = (data) => ajax.post(URLs.getRoomInfo, data)
//编辑酒店房间数量列表
const editRoomInfo = (data) => ajax.post(URLs.editRoomInfo, qs.stringify(data))
//增加不同日期的房间数量
const addRoomNum = (data) => ajax.post(URLs.addRoomNum, qs.stringify(data))
//修改不同日期的房间库存
const addInventory = (data) => ajax.post(URLs.addInventory, data)




export default {
  hotelData,
  hotelAdd,
  uploadImg,
  hotelEdit,
  hotelReserve,
  addRoomType,
  getRoomType,
  hotelDelete,
  exportReserve,
  getRoomInfo,
  editRoomInfo,
  addRoomNum,
  addInventory,
  getBookUserList,
  saveHotelRefundRecord,
  confirmBookingInfo,
}