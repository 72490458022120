<template>
  <div class="home" style="height:100%">
    <el-container style="height:100%">
      <el-aside width="200px" style="height:100%">
        <Aside></Aside>
      </el-aside>
      <el-container>
        <el-header class="header" style="height:75px">
          <Header :admin="admin"></Header>
        </el-header>
        <el-main class="main">
          <router-view v-if="isRouterAlive"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "../components/header.vue"
import Aside from "../components/aside.vue"
export default {
  name: "Home",
  components: { Header, Aside },
  data() {
    return {
      admin: "",
      isRouterAlive: true,
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  created() {
    this.admin = sessionStorage.getItem("account")
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
  },
}
</script>

<style lang="less">
.header {
  background: linear-gradient(to right, #333, #fff);;
}
.main {
  // background: lightblue;
}
</style>
