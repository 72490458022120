import user from './user';
import competition from './competition';
import group from './group'
import apply from './apply'
import ticket from './ticket';
import refund from './refund';
import schedule from './schedule';
import room from './room';
import invoice from './invoice';
import discount from './discount';
import awardRecord from "./awardRecord";
import raceScore from "./raceScore";


export default {
  //登录相关
  login: user.login,
  logout: user.logout,
  getUserInfo: user.getUserInfo,
  //赛事相关
  addGame: competition.addGame,
  getData: competition.getData,
  deleteData: competition.deleteData,
  clearUnPayApplyList: competition.clearUnPayApplyList,
  cancelCompetition: competition.cancelCompetition,
  isShow: competition.isShow,
  edit: competition.edit,
  baseInfo: competition.baseInfo,
  hotelDrop: competition.hotelDrop,
  unitDrop: competition.unitDrop,
  exportToScore: competition.exportToScore,
  exportOrderInfo: competition.exportOrderInfo,
  uploadCompetitionImg: competition.uploadCompetitionImg,
  //退还费用相关
  findRefundExcelList: group.findRefundExcelList,
  uploadWeChatRefundExcel: group.uploadWeChatRefundExcel,
  refundAliPayOrder: group.refundAliPayOrder,
  uploadApplyExcel: group.uploadApplyExcel,
  //组别相关
  addGroup: group.addGroup,
  groupData: group.groupData,
  deleteGroup: group.deleteGroup,
  deleteAllGroup: group.deleteAllGroup,
  editGroup: group.editGroup,
  importGroup: group.importGroup,
  exportGroup: group.exportGroup,
  //报名相关
  findApply: apply.findApply,
  findApplyUnit: apply.findApplyUnit,
  findApplyUnitPlayer: apply.findApplyUnitPlayer,
  toBePay: apply.toBePay,
  findPersonal: apply.findPersonal,
  exportApplyInfo: apply.exportApplyInfo,
  exportApplyPayInfo: apply.exportApplyPayInfo,
  exportAllPlayers: apply.exportAllPlayers,
  exportApplyUnit: apply.exportApplyUnit,
  exportUnPay: apply.exportUnPay,
  deleteToBePay: apply.deleteToBePay,
  //门票相关
  addTicket: ticket.addTicket,
  addTicketNum: ticket.addTicketNum,
  editTicket: ticket.editTicket,
  deleteTicket: ticket.deleteTicket,
  getTicketData: ticket.getTicketData,
  getPayInfo: ticket.getPayInfo,
  addTicketDis: ticket.addTicketDis,
  getTicketDisData: ticket.getTicketDisData,
  deleteTicketDisData: ticket.deleteTicketDisData,
  exportTicketPayInfo: ticket.exportTicketPayInfo,
  //退款相关
  insertRefund: refund.insertRefund,
  findRefundProportionList: refund.findRefundProportionList,
  editRefund: refund.editRefund,
  deleteRefund: refund.deleteRefund,
  findRefundInfo: refund.findRefundInfo,
  query: refund.query,
  findSpecialRefundList: refund.findSpecialRefundList,
  checkRefund: refund.checkRefund,
  check: refund.check,
  findTicketRefund: refund.findTicketRefund,
  findRefundHotelInfo: refund.findRefundHotelInfo,
  exportApplyRefund: refund.exportApplyRefund,
  exportTicketRefund: refund.exportTicketRefund,
  //赛程证书相关
  querySchedule: schedule.querySchedule,
  clearSchedule: schedule.clearSchedule,
  importSchedule: schedule.importSchedule,
  queryScheduleGroup: schedule.queryScheduleGroup,
  clearScheduleGroup: schedule.clearScheduleGroup,
  importScheduleGroup: schedule.importScheduleGroup,
  queryScore: schedule.queryScore,
  clearScore: schedule.clearScore,
  importScore: schedule.importScore,
  queryCertificate: schedule.queryCertificate,
  importCertificate: schedule.importCertificate,
  clearCertificate: schedule.clearCertificate,
  //酒店相关
  hotelData: room.hotelData,
  hotelAdd: room.hotelAdd,
  uploadImg: room.uploadImg,
  hotelEdit: room.hotelEdit,
  hotelReserve: room.hotelReserve,
  addRoomType: room.addRoomType,
  getRoomType: room.getRoomType,
  hotelDelete: room.hotelDelete,
  exportReserve: room.exportReserve,
  getRoomInfo: room.getRoomInfo,
  editRoomInfo: room.editRoomInfo,
  addRoomNum: room.addRoomNum,
  addInventory: room.addInventory,
  getBookUserList: room.getBookUserList,
  saveHotelRefundRecord: room.saveHotelRefundRecord,
  confirmBookingInfo: room.confirmBookingInfo,
  //发票相关
  getInvoiceList: invoice.getInvoiceList,
  exportInvoice: invoice.exportInvoice,
  confirmInvoice: invoice.confirmInvoice,
  //折扣相关
  getDiscountList: discount.getDiscountList,
  exportDiscount: discount.exportDiscount,
  exportDiscountDetail: discount.exportDiscountDetail,
  exportDiscountTicket: discount.exportDiscountTicket,
  exportDiscountDetailTicket: discount.exportDiscountDetailTicket,
  importDiscountExcel: discount.importDiscountExcel,
  //用户
  userQuery: group.userQuery,
  userAdd: group.userAdd,
  userUpdate: group.userUpdate,
  changePassword: group.changePassword,
  removeUser: group.removeUser,
  //奖品查询
  unitAwardList: awardRecord.unitAwardList,
  awardRecordInfo: awardRecord.awardRecordInfo,
  awardLogisticsList: awardRecord.awardLogisticsList,

  loadBoardYearList: raceScore.loadBoardYearList,
  loadBoardList: raceScore.loadBoardList,
  loadRaceList: raceScore.loadRaceList,
  loadUploadHistory: raceScore.loadUploadHistory,
  importBoardScore: raceScore.importBoardScore,
  loadBoardPlayer: raceScore.loadBoardPlayer,
  boardRefresh: raceScore.boardRefresh,
  deleteScoreBoard: raceScore.deleteScoreBoard,
  saveScoreBoardYear: raceScore.saveScoreBoardYear,
  saveScoreBoard: raceScore.saveScoreBoard,
  resetBoardScore: raceScore.resetBoardScore,
  loadBoardPlayerRecord: raceScore.loadBoardPlayerRecord,
  deleteBoardPlayerRecord: raceScore.deleteBoardPlayerRecord,
  saveBoardPlayerRecord: raceScore.saveBoardPlayerRecord,
  saveBoardPlayer: raceScore.saveBoardPlayer,
  imageUploadConfig: raceScore.imageUploadConfig
}
