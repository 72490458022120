import ajax from './request'
import qs from 'qs'

const URLs = {
  getInvoiceList: process.env.VUE_APP_HOST_TEST + '/competition/invoice/queryInvoice',//获取发票列表
  exportInvoice: process.env.VUE_APP_HOST_TEST + '/competition/invoice/downloadInvoice',//导出发票申请信息
  confirmInvoice: process.env.VUE_APP_HOST_TEST + '/competition/invoice/confirm',//确认发票信息

}
const getInvoiceList = (options) => ajax.post(URLs.getInvoiceList,options)
const exportInvoice = (data) => ajax.post(URLs.exportInvoice, data,{ responseType: 'arraybuffer' })
const confirmInvoice = (data) => ajax.post(URLs.confirmInvoice, data)

export default {
  getInvoiceList,
  exportInvoice,
  confirmInvoice,
}